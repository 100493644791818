import React from "react"
import styled from "styled-components"
import Loader from "react-loader-spinner"
import { Colors } from "../../colors/Colors"

const Spinner = styled.div`
  text-align: center;
  margin: 20px 0;
`

const LoadingSpinner = () => (
  <Spinner>
    <Loader type="Oval" color={Colors.gold} height={50} width={50} />
  </Spinner>
)

export default LoadingSpinner
