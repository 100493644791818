import React from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { motion } from "framer-motion/dist/framer-motion"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 550px) {
    margin-bottom: 20px;
  }
`
const Container = styled(motion.div)`
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  border-radius: 7px;
  min-width: 250px;
  position: relative;
  height: 120px;
  overflow: hidden;
  justify-content: space-between;
  margin: 0 5px 10px 0;
  cursor: pointer;
  border: ${props =>
    props.selected ? `1px solid ${Colors.gold}` : `2px solid #efefef`};
  transition: 0.4s ease-out;
`
const Text = styled.div`
  text-align: left;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
`
const Cover = styled.img`
  height: 100%;
  width: 30%;
  margin: 0;
  object-fit: cover;
`
const Name = styled.h2`
  font-family: "Articulat";
  font-size: 14px;
  font-weight: 600;
  color: #2b2b2b;
  margin: 0 0 5px 0;
`
const Address = styled.address`
  font-size: 14px;
  font-family: "Articulat";
  color: #666666;
  margin: 0;
  line-height: 20px;
`
const Availability = styled.p`
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 5px 0;
  opacity: 0.6;
  line-height: 1.2;
  background: white;
  border-radius: 3px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  margin-right: 5px;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
`

const OfficeCard = ({ selected, selectOffice, name, address, cover }) => (
  <Wrapper>
    <Container
      whileHover={{ y: -10 }}
      onClick={() => selectOffice(name)}
      selected={selected}
    >
      <Text>
        <Name>{name}</Name>
        <Address>{address}</Address>
      </Text>
      <Cover src={cover} />
    </Container>
    {name === "Smile Inn Woodbrook" ? (
      <>
        <Availability>Dr. Shenilee Hazell: Mon - Thu</Availability>
        <Availability>Dr. Sachi Seunath: Friday</Availability>
      </>
    ) : (
      <>
        <Availability>Dr. Shenilee Hazell: Friday</Availability>
        <Availability>Dr. Sachi Seunath: Mon - Thu</Availability>
      </>
    )}
  </Wrapper>
)

export default OfficeCard
