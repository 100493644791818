import React, { useState } from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { Title } from "./appointment-styles"
import LoadingSpinner from "./loading-spinner"
import MainButton from "../main-button"
import { useEffect } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

const Container = styled.div`
  padding-top: 30px;
`
const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const Input = styled.input`
  border: 1px solid #f3f3f3;
  font-size: 16px;
  font-family: "Articulat";
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 2px;

  ::placeholder {
    font-size: 14px;
  }

  :focus {
    outline: none;
    border-bottom: 2px solid ${Colors.gold};
  }
`

const ContactInformation = ({ handleSubmit, uploading }) => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [note, setNote] = useState("")

  useEffect(() => {
    //Scroll to top of container on mount
    scrollTo("#container")
  }, [])

  return (
    <Container id="container">
      <Title>Please enter your contact information</Title>
      <FormContainer>
        <Form noValidate>
          <Input
            name="fullName"
            id="fullName"
            value={fullName}
            onChange={e => setFullName(e.target.value)}
            style={{ textTransform: "capitalize" }}
            autoCapitalize="words"
            type="text"
            placeholder="Full name"
          />
          <Input
            name="emailAddress"
            id="emailAddress"
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            placeholder="Email address"
          />
          <Input
            name="phoneNumber"
            id="phoneNumber"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            type="text"
            placeholder="Phone Number"
          />
          <Input
            name="note"
            id="note"
            value={note}
            onChange={e => setNote(e.target.value)}
            type="text"
            placeholder="Comments/Special Requests (Optional)"
          />

          {uploading ? (
            <LoadingSpinner />
          ) : (
            <MainButton
              onClick={e => handleSubmit(e, fullName, email, phoneNumber, note)}
            >
              Confirm Booking
            </MainButton>
          )}
        </Form>
      </FormContainer>
    </Container>
  )
}

export default ContactInformation
