import React from "react"
import styled from "styled-components"
import { Title } from "./appointment-styles"
import { Link } from "gatsby"
import { Colors } from "../../colors/Colors"
import moment from "moment"

const DoneContainer = styled.div`
  text-align: left;
  width: 100%;
`
const Details = styled.p`
  font-size: 15px;
  font-family: "Articulat";
`
const ConfirmationContainer = styled.div`
  background-color: #edeeee;
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem 2rem;
`
const Service = styled.h2`
  font-family: "Articulat";
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: 20px;
`
const SmallTitle = styled.h3`
  color: #a0a0a0;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 12px;
  margin-bottom: 10px;
  font-family: "Articulat";
`
const Location = styled.address`
  font-family: "Articulat";
  font-style: inherit;
  font-weight: 500;
  line-height: 120%;
  color: #484848;
`

const BookingConfirmation = ({ service, location, date }) => (
  <DoneContainer>
    <Title style={{ fontSize: "29px", marginBottom: "10px" }}>
      We've booked your appointment!
    </Title>
    <Details>Here are the details of your visit:</Details>
    <ConfirmationContainer>
      <SmallTitle>Appointment Type</SmallTitle>
      <Service>{service.name}</Service>
      <SmallTitle>Location</SmallTitle>
      <Location>{location}</Location>
      <SmallTitle>Appointment Date</SmallTitle>
      <Location>{moment(date).format("dddd Do MMM, h:mm a")}</Location>
    </ConfirmationContainer>
    <Details
      style={{
        marginTop: "20px",
        lineHeight: "23px",
        fontWeight: 300,
      }}
    >
      Your spot has been reserved! As soon as the Clinic Coordinator confirms
      your appointment we will email to let you know. Thank you choosing Smile
      Inn and we can't wait to welcome you!
    </Details>
    <Details>
      Is this your first time visiting? Check out our <b>New Patient</b> and{" "}
      <b>Insurance Claim</b> forms{" "}
      <Link style={{ color: Colors.gold }} to="/patient-forms">
        here
      </Link>
    </Details>
  </DoneContainer>
)

export default BookingConfirmation
