import styled from "styled-components"
// import { motion } from "framer-motion"
import { motion } from "framer-motion/dist/framer-motion"

export const Title = styled.h2`
  display: ${props => props.hidden && "none"};
  color: #2b2b2b;
  font-weight: 300;
  font-size: 22px;
  font-family: "Articulat";
  letter-spacing: -1px;
  margin-bottom: 1rem;
`
export const Subtitle = styled(motion.p)`
  color: #2b2b2b;
  font-weight: 300;
  font-size: 15px;
  font-family: "Articulat";
`
