import React, { Component } from "react"
import styled, { keyframes } from "styled-components"
import moment from "moment"
import emailjs from "emailjs-com"
import axios from "axios"
import { getTimesForDay, addAppointment } from "../services/parse-bridge"
import "../components/layout.css"
import Fade from "react-reveal/Fade"
import MainButton from "../components/main-button"
import { Colors } from "../colors/Colors"
import Loader from "react-loader-spinner"
import Lottie from "lottie-react-web"
import animation from "../animations/972-done.json"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import SEO from "../components/seo"
import {
  FaArrowLeft,
  FaArrowRight,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa"
import Layout from "../components/layout"
import { Link, navigate } from "gatsby"
import ServiceList from "../components/appointment/service-list"
import AppointmentDateStep from "../components/appointment/appointment-date"
import { getTimesForWoodbrook, getTimesForDiego } from "../utils/appointment"
import ContactInformation from "../components/appointment/contact-information"
import BookingConfirmation from "../components/appointment/booking-confirmation"
import { openPopupWidget } from "react-calendly"
import {
  CALENDLY_PAGE_SETTINGS,
  CALENDLY_URL,
} from "../../shared/project-constants"
class AppointmentPage extends Component {
  constructor(props) {
    super(props)
    this.tagRefs = {}
    this.timeRef = React.createRef()
    this.dateRef = React.createRef()
    this.formRef = React.createRef()

    this.state = {
      dates: [],
      times: [],
      selectedOffice: "",
      selectedDate: null,
      selectedService: "",
      serviceType: "",
      fullName: "",
      emailAddress: "",
      phoneNumber: "",
      note: "",
      start: null,
      end: null,
      uploading: false,
      loadingTimes: false,
      navOpen: false,
      confirmationModalOpen: false,
      confirmationTextVisible: false,
      stepIndex: 0,
      appointmentDateSelected: false,
      appointmentMeridiem: 0,
      validEmail: false,
      validPhone: false,
      confirmationSnackbarOpen: false,
      loading: false,
      activeIndex: null,
    }
  }

  componentDidMount() {
    this.openCalendlyWidget()
  }

  openCalendlyWidget = () => {
    openPopupWidget({
      url: CALENDLY_URL,
      prefill: {},
      pageSettings: CALENDLY_PAGE_SETTINGS,
      utm: {},
    })
  }

  renderDates = location => {
    console.log("Location: " + location)
    if (location === "") {
      return
    }
    let startDate = new Date()
    let dates = []
    let includedDays

    if (location === "Smile Inn Woodbrook") {
      includedDays = ["Mon", "Tue", "Thu"]
    } else {
      includedDays = ["Mon", "Tue", "Wed", "Thu"]
    }
    //Loop through the next 4 weeks
    for (var i = 0; i < 30; i++) {
      let dateObj = {
        day: moment(startDate)
          .add(i, "days")
          .format("ddd"),
        month: moment(startDate)
          .add(i, "days")
          .format("MMM D"),
        date: moment(startDate)
          .add(i, "days")
          .toDate(),
      }

      if (includedDays.includes(dateObj.day) === true) {
        dates.push(dateObj)
      }
    }

    this.setState(
      { dates, selectedDate: dates[0].date, loadingTimes: true },
      () => {
        this.renderTimes(dates[0].date)
      }
    )
  }

  renderBookedAvailablity = async (date, location, index) => {
    const day = moment(date).format("ddd")
    let startTime = moment(date)
      .startOf("day")
      .add(7, "hours")
      .toDate()

    let allBooked

    //Get the day's bookings, based on location
    let bookings = await getTimesForDay(date, location)
    let bookedDates = bookings.map(item => {
      return moment(item.date).toString()
    })

    if (location === "Smile Inn Woodbrook") {
      const bookedTimes = getTimesForWoodbrook(day, startTime, bookedDates)
      allBooked =
        bookedTimes.filter(el => el.booked).length === bookedTimes.length
    }
    if (location === "Smile Inn Diego Martin") {
      const bookedTimes = getTimesForDiego(day, startTime, bookedDates)
      allBooked =
        bookedTimes.filter(el => el.booked).length === bookedTimes.length
    }

    return {
      date,
      dayBooked: allBooked,
      index,
    }
  }

  renderTimes = async (date, location) => {
    this.setState({ loadingTimes: true })
    const day = moment(date).format("ddd")

    //Get the day's bookings, based on location
    let bookings = await getTimesForDay(date, location)
    let bookedDates = bookings.map(item => {
      return moment(item.date).toString()
    })

    //Go through times and filter booked hours
    let startTime = moment(date)
      .startOf("day")
      .add(7, "hours")
      .toDate()

    if (location === "Smile Inn Woodbrook") {
      this.setState({
        times: getTimesForWoodbrook(day, startTime, bookedDates),
        loadingTimes: false,
      })
    }
    if (location === "Smile Inn Diego Martin") {
      this.setState({
        times: getTimesForDiego(day, startTime, bookedDates),
        loadingTimes: false,
      })
    }
  }

  handleServiceSelect = service => {
    this.setState({ selectedService: service, stepIndex: 1 })
  }

  handleTimeSlotSelect = (date, location) => {
    this.setState({ loading: true, stepIndex: null })
    setTimeout(() => {
      this.setState({
        selectedDate: date,
        stepIndex: 2,
        loading: false,
        selectedOffice: location,
      })
    }, 200)
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  scrollTo = id => {
    this.tagRefs[id].scrollIntoView(true)
  }

  sendEmail = (fullName, email, phoneNumber, note) => {
    //Start loading
    this.setState({ isLoading: true })

    //Get input from state
    const { selectedService, selectedOffice, selectedDate } = this.state

    const template_params = {
      name: fullName,
      location: selectedOffice,
      serviceType: selectedService.name,
      phone: phoneNumber,
      email: email,
      date: moment(selectedDate).format("dddd Do MMM, YYYY [at] h:mm a"),
      notes: note,
    }

    emailjs
      .send(
        "gmail",
        "new_appointment",
        template_params,
        "user_BcIlH8mQyisHGYizwLSu0"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text)
        },
        err => {
          console.log("FAILED...", err)
        }
      )
  }

  handleSubmit = (event, fullName, email, phoneNumber, note) => {
    event.preventDefault()

    const { selectedService, selectedOffice, selectedDate } = this.state

    //Validity Check
    if (fullName === "" || email === "" || phoneNumber < 7) {
      alert(
        "Please fill all fields correctly! This helps us to better serve you"
      )
      return
    }

    this.setState({ uploading: true })

    addAppointment(
      selectedDate,
      fullName,
      email,
      phoneNumber,
      note,
      selectedService.name,
      selectedOffice
    ).then(res => {
      //Stop loading
      this.setState({ uploading: false })
      if (res.success) {
        this.sendEmail(fullName, email, phoneNumber, note)
        this.setState({ stepIndex: 3 })
      } else {
        alert(res.error)
      }
    })
  }

  render() {
    const {
      dates,
      times,
      selectedDate,
      stepIndex,
      loadingTimes,
      selectedService,
      selectedOffice,
      uploading,
      loading,
    } = this.state

    return <div />
    return (
      <Layout noNav noBanner>
        <Container>
          <SEO title="Make an appointment online" />
          <BookingContainer ref={this.formRef}>
            <Inner key={stepIndex}>
              <Logo
                onClick={() => navigate("/")}
                src={require("../images/logo.png")}
              />
              <hr style={{ width: "100%", opacity: 0.2 }} />
              {loading && (
                <Spinner>
                  <Loader
                    type="Oval"
                    color={Colors.gold}
                    height={50}
                    width={50}
                  />
                </Spinner>
              )}
              {stepIndex === 0 && (
                <ServiceList selectService={this.handleServiceSelect} />
              )}
              {stepIndex === 1 && (
                <AppointmentDateStep
                  times={times}
                  renderTimes={this.renderTimes}
                  renderBookedAvailablity={this.renderBookedAvailablity}
                  loadingTimes={loadingTimes}
                  selectTimeSlot={this.handleTimeSlotSelect}
                />
              )}
              {stepIndex === 2 && (
                <ContactInformation
                  uploading={uploading}
                  handleSubmit={this.handleSubmit}
                />
              )}
              {stepIndex === 3 && (
                <BookingConfirmation
                  service={selectedService}
                  location={selectedOffice}
                  date={selectedDate}
                />
              )}
            </Inner>
          </BookingContainer>
        </Container>
      </Layout>
    )
  }
}

export default AppointmentPage

const fadeIn = keyframes`
  from {
          opacity: 0;
      }
  to {
          opacity: 1;
      }
    `
const Spinner = styled.div`
  text-align: center;
  margin: 20px 0;
  /* min-height: 550px; */
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`
const BookingContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 30px 20px;
`

const Inner = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
`
const Logo = styled.img`
  max-width: 200px;
  margin-bottom: 20px;
  cursor: pointer;
`
