import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Title, Subtitle } from "./appointment-styles"
import OfficeCard from "./office-card"
// import { motion } from "framer-motion"
import { renderBookingDates } from "../../utils/appointment"
import Loader from "react-loader-spinner"
import { Colors } from "../../colors/Colors"
import LoadingSpinner from "./loading-spinner"
import scrollTo from "gatsby-plugin-smoothscroll"
import { motion } from "framer-motion/dist/framer-motion"

const dateContainer = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    y: 10,
    transition: {
      when: "afterChildren",
    },
  },
}

const dateItem = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 20 },
}
const Container = styled.div``
const OfficeCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 30px 0 50px;
  /* overflow-x: scroll; */
  width: 100%;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`
const DatesContainer = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 50px;
  width: 100%;
  flex-wrap: wrap;
  /* overflow-x: scroll; */
`
const DateItem = styled(motion.button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 5px 10px 0;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 5px;
  background: #fbfbfb;
  font-size: 16px;
  border: none;
  color: #252525;
  padding: 8px 15px;
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  :disabled {
    background: #eee;
  }
`
const DateText = styled.p`
  font-family: "Articulat";
  font-size: 16px;
  color: ${props => (props.selected ? "#C79836" : "#2b2b2b")};
  white-space: nowrap;
  margin: 0;
  text-decoration: ${props => props.disabled && "line-through"};
`
const Day = styled.h3`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: ${props => (props.selected ? "#C79836" : "#2b2b2b")};

  margin: 0;
  font-family: "Articulat";
`
const TimesContainer = styled.div`
  display: ${props => (props.hidden ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0 50px;
  width: 100%;
`
const TimeItem = styled.div`
  margin: 5px;
`
const Time = styled.button`
  cursor: ${props => (props.booked ? "not-allowed" : "pointer")};
  font-family: "Articulat";
  border-radius: 5px;
  font-weight: 600;
  background: #f1f1f1;
  font-size: 16px;
  border: none;
  color: #252525;
  padding: 5px 10px;
  min-width: 100px;

  :disabled {
    color: #dddddd;
  }
`
class AppointmentDateStep extends React.Component {
  constructor(props) {
    super(props)

    this.dateRef = React.createRef()
    this.timeRef = React.createRef()

    this.state = {
      officeLocation: "",
      selectedDate: null,
      dates: [],
      bookedDays: [],
    }
  }

  handleSelectOffice = office => {
    //Reset values
    this.setState({ dates: [], selectedDate: null })
    console.log("Office selected, rendering days based on office")
    this.setState(
      { officeLocation: office, dates: renderBookingDates(office) },
      async () => {
        //Scroll to container
        this.dateRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })

        const getTimesPromise = this.state.dates.map((date, index) => {
          return this.props.renderBookedAvailablity(
            date.date,
            this.state.officeLocation,
            index
          )
        })
        const dayAvailabilityObj = await Promise.all(getTimesPromise)
        this.setState({ bookedDays: dayAvailabilityObj })
      }
    )
  }

  handleDateSelect = date => {
    const { officeLocation } = this.state
    this.setState({ selectedDate: date.date, loadingTimes: true })
    this.props.renderTimes(date.date, officeLocation)
  }

  render() {
    const { officeLocation, selectedDate, dates } = this.state
    const { times, loadingTimes, selectTimeSlot } = this.props
    return (
      <Container>
        <Title>Which office will you be visiting?</Title>
        <Subtitle>
          We have two convenient office locations available to you
        </Subtitle>
        <OfficeCards>
          {offices.map((office, index) => (
            <OfficeCard
              key={index}
              name={office.name}
              address={office.address}
              cover={office.cover}
              selected={office.name === officeLocation}
              selectOffice={this.handleSelectOffice}
            />
          ))}
        </OfficeCards>
        {officeLocation !== "" && (
          <div ref={this.dateRef} id="date">
            <Title>Choose an available date</Title>
            <DatesContainer
              initial="hidden"
              animate={officeLocation !== "" ? "visible" : "hidden"}
              variants={dateContainer}
            >
              {dates.map((date, index) => (
                <DateItem
                  variants={dateItem}
                  disabled={
                    this.state.bookedDays[index] &&
                    this.state.bookedDays[index].dayBooked
                  }
                  onClick={() => this.handleDateSelect(date)}
                  // onClick={() => this.handleDateSelect(date.date)}
                  key={index}
                  transition={{ duration: 0.3 }}
                >
                  <Day selected={date.date === selectedDate}>{date.day}</Day>
                  <DateText
                    disabled={
                      this.state.bookedDays[index] &&
                      this.state.bookedDays[index].dayBooked
                    }
                    selected={date.date === selectedDate}
                  >
                    {date.month}
                  </DateText>
                </DateItem>
              ))}
            </DatesContainer>
          </div>
        )}
        {loadingTimes ? (
          <LoadingSpinner />
        ) : (
          <>
            <Title hidden={!selectedDate}>Choose a time</Title>
            <TimesContainer ref={this.timeRef} hidden={!selectedDate}>
              {times.map((time, index) => (
                <TimeItem
                  onClick={() => selectTimeSlot(time.date, officeLocation)}
                  key={index}
                  booked={time.booked}
                  key={index}
                >
                  <Time booked={time.booked} disabled={time.booked}>
                    {time.timeStamp}
                  </Time>
                </TimeItem>
              ))}
            </TimesContainer>
          </>
        )}
      </Container>
    )
  }
}

export default AppointmentDateStep

const offices = [
  {
    name: "Smile Inn Woodbrook",
    address: "5 Roberts Street, Woodbrook",
    cover: require("../../images/woodbrook.jpg"),
  },
  {
    name: "Smile Inn Diego Martin",
    address: "109 Diego Martin Main Rd, Trinidad & Tobago",
    cover:
      "https://www.cirrusconsultinggroup.com/wp-content/uploads/2018/08/Large-dental-office.jpeg",
  },
]
