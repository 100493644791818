import React, { useState } from "react"
import styled from "styled-components"
import { Title, Subtitle } from "./appointment-styles"
import Fade from "react-reveal/Fade"

const Container = styled.div``
const ServiceItem = styled.button`
  background: #f1f1f1;
  font-family: "Articulat";
  font-weight: 500;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 0;
  border-radius: 32px;
  margin-bottom: 10px;
  transition: 0.3s ease;
  cursor: pointer;

  :hover {
    background: #d6d6d6;
  }

  :focus {
    outline: none;
  }
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Disclaimer = styled.div`
  background: #fbdea0;
  padding: 9px;
  margin-bottom: 31px;
  /* color: white; */
  border-radius: 4px;

  p {
    margin: 0;
  }
`

const ServiceList = ({ selectService }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  return (
    <Container>
      <Title>What type of appointment are you making?</Title>
      <Subtitle>
        In most cases, it's best to start with a{" "}
        <span style={{ textDecoration: "underline", fontWeight: 600 }}>
          General Consultation
        </span>{" "}
        so we can properly assess what needs to be done
      </Subtitle>
      <Disclaimer>
        <p>
          Unfortunately, all appointments are booked for the month of December
        </p>
      </Disclaimer>
      <List>
        {serviceList.map((item, index) => (
          <div key={index}>
            <ServiceItem
              onMouseEnter={() => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(null)}
              onClick={() => console.log("disabled")}
              // onClick={() => selectService(item)}
            >
              {item.name}
            </ServiceItem>
            {activeIndex === index && (
              <Subtitle
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
              >
                {item.description}
              </Subtitle>
            )}
          </div>
        ))}
      </List>
    </Container>
  )
}

export default ServiceList

const serviceList = [
  {
    name: "General Consultation",
    description:
      "A short check up where we can properly assess what work needs to be done",
  },
  {
    name: "Cleaning",
    description:
      "A professional teeth cleaning at Smile Inn gets all the plaque and tartar off your teeth",
  },
  {
    name: "Professional Teeth Whitening",
    description:
      "Teeth whitening is designed to remove both intrinsic and extrinsic stains safely when used as directed",
  },
  {
    name: "Invisalign Consultation",
    description:
      "A modern, fully digitized experience to help you get the smile you want, the way you want. Invisalign uses a series of aligner trays that are replaced every one to two weeks.",
  },
]
