import moment from "moment"

export const renderBookingDates = location => {
  if (location === "") {
    return []
  }
  let startDate = new Date()
  let dates = []
  let includedDays

  if (location === "Smile Inn Woodbrook") {
    includedDays = ["Mon", "Tue", "Thu"]
  } else {
    includedDays = ["Mon", "Tue", "Wed", "Thu"]
  }
  //Loop through the next 4 weeks
  for (var i = 0; i < 30; i++) {
    let dateObj = {
      day: moment(startDate)
        .add(i, "days")
        .format("ddd"),
      month: moment(startDate)
        .add(i, "days")
        .format("MMM D"),
      date: moment(startDate)
        .add(i, "days")
        .toISOString(),
    }

    if (includedDays.includes(dateObj.day) === true) {
      dates.push(dateObj)
    }
  }
  return dates
}

export const getTimesForWoodbrook = (day, startTime, bookedDates) => {
  //Create an empty object for returning later
  let times = []

  if (day === "Mon") {
    let currentMoment = moment(startTime).add(16 * 30, "minutes")

    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
  } else if (day === "Thu") {
    let currentMoment = moment(startTime).add(11 * 30, "minutes")

    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
  } else {
    var currentMoment = moment(startTime).add(4 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
    currentMoment = moment(startTime).add(6 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
  }

  return times
}

export const getTimesForDiego = (day, startTime, bookedDates) => {
  //Create an empty object for returning later
  let times = []

  if (day === "Wed") {
    let currentMoment = moment(startTime).add(12 * 30, "minutes")

    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
    currentMoment = moment(startTime).add(17 * 30, "minutes")

    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
  }else if (day === "Fri") {
    var currentMoment = moment(startTime).add(8 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
    currentMoment = moment(startTime).add(10 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
    currentMoment = moment(startTime).add(12 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
    currentMoment = moment(startTime).add(13 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
  } else {
    var currentMoment = moment(startTime).add(4 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
    currentMoment = moment(startTime).add(8 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
    currentMoment = moment(startTime).add(12 * 30, "minutes")
    times.push({
      timeStamp: currentMoment.format("h:mm: a"),
      booked: bookedDates.includes(currentMoment.toString()),
      date: currentMoment.toDate(),
    })
  }

  return times
}
// export const getTimesForDiego = (day, startTime, bookedDates) => {
//   //Create an empty object for returning later
//   let times = []

//   if (day === "Sat") {
//     //Saturday's start at 8, end at 1
//     for (let i = 1; i < 7; i++) {
//       let currentMoment = moment(startTime).add(1 * i, "hours")

//       times.push({
//         timeStamp: currentMoment.format("h:mm a"),
//         booked: bookedDates.includes(currentMoment.toString()),
//         date: currentMoment.toDate(),
//       })
//     }
//   } else {
//     for (let i = 0; i < 10; i++) {
//       let currentMoment = moment(startTime).add(1 * i, "hours")

//       times.push({
//         timeStamp: currentMoment.format("h:mm a"),
//         booked: bookedDates.includes(currentMoment.toString()),
//         date: currentMoment.toDate(),
//       })
//     }
//   }

//   return times
// }
